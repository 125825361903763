"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryIBL = exports.IBL_SERVICE_ENDPOINT = exports.IBL_SERVICE_ENDPOINT_TEST = void 0;
// Declare constants
exports.IBL_SERVICE_ENDPOINT_TEST = 'https://ibl.service.iwize.nl/env/test/api/v1/ibl';
exports.IBL_SERVICE_ENDPOINT = 'https://ibl.service.iwize.nl/env/prod/api/v1/ibl';
function queryIBL(blob_1) {
    return __awaiter(this, arguments, void 0, function* (blob, useTest = undefined) {
        var _a;
        try {
            // Perform query
            const response = yield fetch(useTest ? exports.IBL_SERVICE_ENDPOINT_TEST : exports.IBL_SERVICE_ENDPOINT, {
                method: 'POST',
                body: JSON.stringify(blob),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // Declare error
            let error;
            // Check if request was succesful
            if (!response.ok)
                console.log('Error fetching IBL', error = yield response.json());
            // Get the result
            const iblResult = response.ok ? yield response.json() : { SVLoon: 0, SV_LoonVast: 0, SV_LoonNietVast: 0, Informatie: ((_a = error === null || error === void 0 ? void 0 : error.error) === null || _a === void 0 ? void 0 : _a.message) ? error.error.message + ' - code ' + error.error.code : 'Error invoking IBL: ' + response.statusText + ':' + response.status };
            // Map and return result
            return {
                pensioenBijdrage: iblResult['Pensioenbijdrage'],
                signatureGeldig: iblResult['SignGeldig'],
                signatureDatum: iblResult['SignDatum'],
                signatureNietOuderdan3Maanden: iblResult['SignNietOuderdan3Maanden'],
                signatureNogMinimaal3WekenGeldig: iblResult['SignNogMinimaal3WekenGeldig'],
                toetsinkomen: iblResult['SVLoon'],
                beslisboomMelding: iblResult['BeslisboomMelding'],
                informatie: iblResult['Informatie'],
                versie: iblResult['Versie'],
                svLoonVast: iblResult['SV_LoonVast'],
                svLoonNietVast: iblResult['SV_LoonNietVast'],
                svPensioenBijdrage: iblResult['SV_PensioenBijdrage'],
                svBerekeningSamenstelling: iblResult['SV_BerekeningSamenstelling']
            };
        }
        catch (e) {
            console.log('Unexpected error: ', e);
            return { SVLoon: 0, SV_LoonVast: 0, SV_LoonNietVast: 0, Informatie: 'Error: ' + e.message };
        }
    });
}
exports.queryIBL = queryIBL;
exports.default = {
    queryIBL,
    IBL_SERVICE_ENDPOINT: exports.IBL_SERVICE_ENDPOINT
};
