"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTicketInfo = void 0;
const getDefaultTicket = () => {
    return {
        jwt: null,
        dataleverancierId: null,
        caseId: null
    };
};
const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};
const createTicketInfo = (jwt) => {
    let result = getDefaultTicket();
    try {
        if (jwt && typeof jwt === 'string') {
            const jwtParseResult = parseJwt(jwt);
            result.jwt = jwt;
            result.caseId = jwtParseResult.caseId || null;
            result.dataleverancierId = result.caseId ? btoa(JSON.stringify({ caseId: result.caseId })) : null;
        }
    }
    catch (e) {
        console.log('Error creating ticket info: ' + e.message, e);
        result = getDefaultTicket();
    }
    return result;
};
exports.createTicketInfo = createTicketInfo;
