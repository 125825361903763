"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nfeNameWorkAround = exports.resetNfeNameWorkAround = void 0;
let naam = null;
const resetNfeNameWorkAround = () => {
    naam = null;
};
exports.resetNfeNameWorkAround = resetNfeNameWorkAround;
const nfeNameWorkAround = (request) => {
    try {
        if (request.event === '&get-outer-html-success' && request.id === 'mbd-welkom-naam') {
            const payload = decodeURIComponent(escape(atob(request.payload)));
            const regex = new RegExp('(^.+Mijn Belastingdienst van )([a-zA-Z\\s]+)(<.+)');
            const execResult = regex.exec(payload);
            naam = execResult && execResult.length > 3 ? execResult[2] : null;
            naam = '<bldc-navigation-user-info>' + naam + '</bldc-navigation-user-info>';
        }
        else if (request.event === '&get-outer-html-success' && request.id === 'mbd-verzamelinkomens' && naam) {
            const payload = decodeURIComponent(escape(atob(request.payload)));
            const html = '<div>' + naam + payload + '</div>';
            request.payload = btoa(unescape(encodeURIComponent(html)));
        }
    }
    catch (e) {
        console.log('Error in nfeNameWorkAround', e);
    }
};
exports.nfeNameWorkAround = nfeNameWorkAround;
