"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isElectron = void 0;
const isElectron = () => {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }
    // Main process
    // if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    //     return true;
    // }
    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }
    return false;
};
exports.isElectron = isElectron;
