"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElectronChannelProxy = void 0;
// Import event source
const event_source_1 = require("../../event-source/event-source");
// No operation.
const noop = () => { };
class ElectronChannelProxy extends event_source_1.EventSource {
    constructor() {
        var _a;
        super();
        /* --- Helpers --- */
        this.handleMessage = (msg) => {
            if (!msg || msg.name !== 'aqopi-to-requestor')
                return;
            // Trigger event
            this.triggerEvent('message', msg);
        };
        (_a = window.electronBridge) === null || _a === void 0 ? void 0 : _a.setListener((msg) => {
            this.triggerEvent('message', msg);
        });
    }
    openTab(cookies, selectorMap) {
        var _a;
        return (_a = window.electronBridge) === null || _a === void 0 ? void 0 : _a.openTab(cookies, selectorMap);
    }
    setTemplates(templates) {
        var _a;
        return (_a = window.electronBridge) === null || _a === void 0 ? void 0 : _a.setTemplates({ templates });
    }
    closeTab() {
        noop();
    }
    handleSetFocusToRequestorTab() {
        var _a;
        (_a = window.electronBridge) === null || _a === void 0 ? void 0 : _a.handleSetFocusToRequestorTab();
    }
    handleSetFocusToContentTab() {
        var _a;
        (_a = window.electronBridge) === null || _a === void 0 ? void 0 : _a.handleSetFocusToContentTab();
    }
    handleAction(action) {
        var _a;
        return (_a = window.electronBridge) === null || _a === void 0 ? void 0 : _a.handleAction(action);
    }
    release() {
        noop();
    }
    on(event, handler) { super.on(event, handler); }
    off(event, handler) { super.off(event, handler); }
}
exports.ElectronChannelProxy = ElectronChannelProxy;
