"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventSource = void 0;
class EventSource {
    constructor() {
        this.listeners = {};
    }
    on(event, handler) {
        // Ensure event exists in listeners
        if (!(event in this.listeners))
            this.listeners[event] = [];
        // Add handler to listeners
        this.listeners[event].push(handler);
    }
    off(event, handler) {
        if (!(event in this.listeners))
            return;
        if (handler) { // Remove specific handler
            // Get the index
            const index = this.listeners[event].indexOf(handler);
            if (index < 0)
                return;
            // Remove handler
            this.listeners[event].splice(index, 1);
        }
        else { // Remove all handlers
            delete this.listeners[event];
        }
    }
    triggerEvent(event, ...args) {
        if (!(event in this.listeners))
            return;
        // Call all handlers
        this.listeners[event].forEach(handler => handler(...args));
    }
}
exports.EventSource = EventSource;
