"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryFailureType = exports.extensionApi = void 0;
require("reflect-metadata");
// Import to ensure dependency injection token is registered with container before session executor init is called
require("./extension-bridge/extension-bridge");
require("./query-executor/channel-proxy/channel-proxy");
require("./utils/config-loader");
// Export session executor as extension api
const session_executor_1 = require("./session-executor/session-executor");
Object.defineProperty(exports, "extensionApi", { enumerable: true, get: function () { return session_executor_1.SessionExecutor; } });
// Export query failure type
var query_failure_1 = require("./types/query-failure");
Object.defineProperty(exports, "QueryFailureType", { enumerable: true, get: function () { return query_failure_1.QueryFailureType; } });
