"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompleteLog = exports.generateLogResult = exports.init = exports.setHasFailed = exports.setIsTimedOut = exports.url = exports.event = exports.stateAction = exports.state = exports.task = exports.config = exports.error = exports.warn = exports.log = exports.setDisplayLogLevel = void 0;
// Import types
const types_1 = require("./types");
// Import uuid
const uuid_1 = require("uuid");
const browser_info_1 = require("../utils/browser-info");
// Declare constants
const LOGLEVEL_NAME_MAP = {
    [types_1.LogLevel.Error]: 'error',
    [types_1.LogLevel.Warn]: 'warn',
    [types_1.LogLevel.Info]: 'info',
    [types_1.LogLevel.Verbose]: 'verbose',
    [types_1.LogLevel.Debug]: 'debug',
    [types_1.LogLevel.Silly]: 'silly'
};
/* --- Logger --- */
// Internal values
const logStack = [];
const sessionId = (0, uuid_1.v4)();
let isTimedOut = false;
let failed = false;
let taskId;
let minDisplayLogLevel = types_1.LogLevel.Error;
// Settings
function setDisplayLogLevel(displayLogLevel) {
    minDisplayLogLevel = displayLogLevel;
}
exports.setDisplayLogLevel = setDisplayLogLevel;
function log(...data) {
    const message = data.map(obj => String(obj)).join('\n');
    push({ type: 'log', message, level: types_1.LogLevel.Debug });
}
exports.log = log;
function warn(...data) {
    const message = data.map(obj => String(obj)).join('\n');
    push({ type: 'log', message, level: types_1.LogLevel.Warn });
}
exports.warn = warn;
function error(obj, error) {
    if (obj instanceof Error)
        push({ type: 'error', error: { name: obj.name, message: obj.message, stack: obj.stack }, level: types_1.LogLevel.Error });
    else
        push({ type: 'error', message: obj, error: { name: error.name, message: error.message, stack: error.stack }, level: types_1.LogLevel.Error });
}
exports.error = error;
function config(config) {
    push({ type: 'config', config, level: types_1.LogLevel.Info });
}
exports.config = config;
function task(task) {
    push({ type: 'task', task, level: types_1.LogLevel.Info });
}
exports.task = task;
function state(state) {
    push({ type: 'state', state, level: types_1.LogLevel.Info });
}
exports.state = state;
function stateAction(action) {
    push({ type: 'state-action', action, level: types_1.LogLevel.Info });
}
exports.stateAction = stateAction;
function event(event) {
    push({ type: 'event', event, level: types_1.LogLevel.Info });
}
exports.event = event;
function url(url) {
    push({ type: 'url', url, level: types_1.LogLevel.Info });
}
exports.url = url;
function setIsTimedOut() {
    isTimedOut = true;
}
exports.setIsTimedOut = setIsTimedOut;
function setHasFailed() {
    failed = true;
}
exports.setHasFailed = setHasFailed;
function init(id) {
    taskId = id;
    isTimedOut = false;
    failed = false;
    logStack.length = 0;
}
exports.init = init;
// Output generators
function generateLogResult() {
    // Transform log stack to log result
    return logStack.reduce((result, event) => {
        if (event.type === 'url')
            result.urls.push(event.url);
        else if (event.type === 'task')
            result.tasks.push(event.task);
        else if (event.type === 'state')
            result.states.push(event.state);
        else if (event.type === 'event')
            result.events.push(event.event);
        return result;
    }, { urls: [], tasks: [], states: [], events: [] });
}
exports.generateLogResult = generateLogResult;
function getCompleteLog(reason = '') {
    // Return deep copy of log stack
    return {
        customerId: document.location.pathname,
        taskId,
        sessionId,
        isTimedOut,
        successInd: !failed,
        browserType: browser_info_1.browserName,
        error: reason,
        logStack: JSON.parse(JSON.stringify(logStack))
    };
}
exports.getCompleteLog = getCompleteLog;
// Helper
function push(event) {
    // Push onto log stack
    logStack.push(Object.assign(Object.assign({}, event), { timestamp: Date.now() }));
    // Check if log should be displayed
    if (event.level > minDisplayLogLevel)
        return;
    // Get log level name
    const llname = `[${LOGLEVEL_NAME_MAP[event.level]}]`;
    if (event.type === 'log')
        console.log(`${llname} ${event.message}`);
    else if (event.type === 'error')
        console.error(...[[llname, event.message, event.error.name].join(' '), event.error.message, '\n', event.error.stack]);
    else if (event.type === 'config')
        console.log(`${llname} [CONFIG] ${event.config}`);
    else if (event.type === 'task')
        console.log(`${llname} [TASK] ${event.task}`);
    else if (event.type === 'state')
        console.log(`${llname} [STATE] ${event.state}`);
    else if (event.type === 'state-action')
        console.log(`${llname} [STATE-ACTION] ${event.action}`);
    else if (event.type === 'event')
        console.log(`${llname} [EVENT] ${event.event}`);
    else if (event.type === 'url')
        console.log(`${llname} [URL] ${event.url}`);
}
/* --- Export default --- */
exports.default = {
    setDisplayLogLevel, log, warn, error, config, task, state, stateAction, event, url, generateLogResult, getCompleteLog, init, setIsTimedOut, setHasFailed
};
