"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvFromUrl = void 0;
function hostMatchesEnv(host, env) {
    return host.startsWith(env + '.') || host.endsWith('.' + env);
}
function urlMatchesEnv(url, env) {
    return url.includes('/' + env + '/') || url.includes('-' + env);
}
function getEnvFromUrl(url, host) {
    if (!url || !host) {
        return 'dev';
    }
    if (host.includes('localhost') || hostMatchesEnv(host, 'dev') || urlMatchesEnv(url, 'dev')) {
        return 'dev';
    }
    else if (hostMatchesEnv(host, 'test') || urlMatchesEnv(url, 'test')) {
        return 'test';
    }
    else if (hostMatchesEnv(host, 'acct') || urlMatchesEnv(url, 'acct')) {
        return 'acct';
    }
    else if (hostMatchesEnv(host, 'staging') || urlMatchesEnv(url, 'staging')) {
        return 'acct';
    }
    else {
        return 'prod';
    }
}
exports.getEnvFromUrl = getEnvFromUrl;
