"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeBsnFromString = exports.mohRawBsnWorkaround = void 0;
const removeBsnFromString = (target) => {
    const regex = /\b\d{9}\b/g;
    return target.replace(regex, '000000000');
};
exports.removeBsnFromString = removeBsnFromString;
const mohRawBsnWorkaround = (request) => {
    var _a;
    try {
        if (request.event === '&get-outer-html-success' && ((_a = request.id) === null || _a === void 0 ? void 0 : _a.endsWith('-raw'))) {
            const html = decodeURIComponent(escape(atob(request.payload)));
            const strippedHtml = removeBsnFromString(html);
            request.payload = btoa(unescape(encodeURIComponent(strippedHtml)));
        }
    }
    catch (error) {
        console.log('Error stripping BSN', error);
    }
};
exports.mohRawBsnWorkaround = mohRawBsnWorkaround;
