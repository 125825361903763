"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigLoader = void 0;
// Dependecy injection imports
const tsyringe_1 = require("tsyringe");
const query_failure_1 = require("../types/query-failure");
// Declare constants
const PUBLIC_KEY_JSON = '{"alg":"RS256","e":"AQAB","ext":true,"key_ops":["verify"],"kty":"RSA","n":"jpsn799KnvKIVunsQQQkN3Aq7K68q4i_ZZwIHbQEJsbakAAKK8hsn_Ny3lOTFfPQgPdy2DNZ2dVP8QlV_nlafqncH3Jypr8jgWgdR83AvLtY8j6ngRPFDLZrmBFK5EOTwlhwFTrCqI0MBZQqjMu2YspgCISHeZy_6iOyjLcDwm1y-kiqoUSqPw4UOkhtYTZIxp57z8yqsIkkO8b5FrIsJChNZ6OdM2FBwYTnmBKOOdZLzkG1Fp5vfxhmz8w_ECOH5_ZjA1EP9I2IOkbPXFsq4DFgoSs6CnmDXz8KC46CKFL4hlBhsC99Z7PKTya-MPtaaDsL5C30Bh0A0o9kDgsjNw"}';
const CONFIGS_ENDPOINT_MAP = {
    'IWIZE-STUB': {
        config: 'https://config-acct.aqopi.com/stub',
        status: 'https://acct-config-meta.aqopi.com/config'
    },
    'IWIZE-ACCEPTANCE': {
        config: 'https://config-acct.aqopi.com/config',
        status: 'https://acct-config-meta.aqopi.com/config'
    },
    'IWIZE-PRODUCTION': {
        config: 'https://config-prod.aqopi.com/config',
        status: 'https://config-meta.aqopi.com/config'
    }
};
class ConfigLoader {
    loadConfig(configEndpoint, configId) {
        return __awaiter(this, void 0, void 0, function* () {
            // If endpoint is set to a default iwize endpoint, load through default
            if (configEndpoint === 'IWIZE-STUB' || configEndpoint === 'IWIZE-ACCEPTANCE' || configEndpoint === 'IWIZE-PRODUCTION')
                return yield this.loadConfigFromSource(configEndpoint, configId);
            // Ensure url doesn't end in /
            if (/\/$/.test(configEndpoint))
                configEndpoint = configEndpoint.slice(0, -1);
            // Perfrom get config request
            const response = yield fetch(`${configEndpoint}/${configId}`, { method: 'GET' });
            // Ensure respond was ok
            if (!response.ok)
                throw new Error(`Failed to get config ${configId} from ${configId}; response status: ${response.statusText}.`);
            // Get the config
            const config = yield response.json();
            // Check if signature exists
            if (!config.signature)
                throw new Error(`Signature validation failed for config ${configId}`);
            // Validate config signature
            if (!(yield this.validateSignature(config)))
                throw new Error(`Signature validation failed for config ${configId}`);
            // Return config
            return config;
        });
    }
    loadConfigFromSource(source, configId) {
        return __awaiter(this, void 0, void 0, function* () {
            // Get the endpoints
            const { config: configEndpoint, status: statusEndpoint } = CONFIGS_ENDPOINT_MAP[source];
            const statusResponsePromise = fetch(`${statusEndpoint}/${configId}/status`, { method: 'GET' });
            const configResponsePromise = fetch(`${configEndpoint}/${configId}`, { method: 'GET' });
            const results = yield Promise.all([statusResponsePromise, configResponsePromise]);
            // Retrieve config status
            const statusResponse = results[0];
            // Ensure response was ok
            if (!statusResponse.ok)
                throw new Error(`Failed to get status for config ${configId} from source ${source}; response status: ${statusResponse.statusText}.`);
            // Get the status result
            const result = yield statusResponse.json();
            // Ensure config is available
            if (!result.status.available)
                throw new query_failure_1.NotAvailableQueryFailure();
            // Retrieve config
            const configResponse = results[1];
            // Ensure response was ok
            if (!configResponse.ok)
                throw new Error(`Failed to get config ${configId} from ${source}; response status: ${configResponse.statusText}.`);
            // Get the config
            const config = yield configResponse.json();
            // Check if signature exists
            if (!config.signature)
                throw new Error(`Signature validation failed for config ${configId}`);
            // Validate config signature
            if (!(yield this.validateSignature(config)))
                throw new Error(`Signature validation failed for config ${configId}`);
            // Merge availability
            config.available = result.status.available;
            // Return config
            return config;
        });
    }
    validateSignature(config) {
        return __awaiter(this, void 0, void 0, function* () {
            // Ensure keys are initialized
            yield this.awaitKeyInitialization();
            // Grab signature
            const signature = config.signature;
            // Store config without signature
            const configCopy = JSON.parse(JSON.stringify(config));
            delete configCopy.signature;
            const signaturelessConfig = configCopy;
            // Get the encoder
            const encoder = this.getEncoder();
            // Encode data
            const data = encoder.encode(JSON.stringify(signaturelessConfig));
            // Transform config to array buffer
            const signatureArrayBuffer = (new Uint8Array(atob(signature).split('').map(c => c.charCodeAt(0)))).buffer;
            return window.crypto.subtle.verify({ name: 'RSASSA-PKCS1-v1_5', hash: { name: 'SHA-256' } }, this.publicKey, signatureArrayBuffer, data);
        });
    }
    awaitKeyInitialization() {
        return __awaiter(this, void 0, void 0, function* () {
            // If public key hasn't been loaded yet, being loading
            if (!this.publicKey && !this.publicKeyPromise) {
                const serializedPublicKey = JSON.parse(PUBLIC_KEY_JSON);
                this.publicKeyPromise = window.crypto.subtle.importKey('jwk', serializedPublicKey, { name: 'RSASSA-PKCS1-v1_5', hash: { name: 'SHA-256' } }, false, ['verify']);
            }
            // Wait for public key loading to complete
            if (!this.publicKey && this.publicKeyPromise) {
                const key = yield this.publicKeyPromise;
                this.publicKey = key;
            }
        });
    }
    getEncoder() {
        if ('TextEncoder' in window)
            return new TextEncoder();
        return {
            encode: (str) => {
                return Uint8Array.from(str, (c) => c.codePointAt(0));
            }
        };
    }
}
exports.ConfigLoader = ConfigLoader;
// Register with DI container
tsyringe_1.container.register('ConfigLoader', { useValue: new ConfigLoader() });
