"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChromeExternallyConnectableExtensionBridge = void 0;
// Import event source
const event_source_1 = require("../event-source/event-source");
// Import query executor
const query_executor_1 = require("../query-executor/query-executor");
// Import config
const extension_api_config_1 = require("../config/extension-api-config");
// Import types
const events_1 = require("../types/events");
// Define constants
const MAX_INSTALL_ATTEMPTS = 60;
const INSTALL_INTERVAL = 1000;
class ChromeExternallyConnectableExtensionBridge extends event_source_1.EventSource {
    constructor(options) {
        super();
        // Init state
        this.state = {
            installationUrl: options.installUrl,
            configEndpoint: options.configEndpoint,
            extensionId: options.extensionId,
            debug: !!options.debug,
            installed: false
        };
    }
    setLoggingStatus(loggingStatus) {
        chrome.runtime.sendMessage(this.state.extensionId, {
            name: events_1.AqopiEventType.SetLoggingStatusRequest,
            loggingStatus
        });
    }
    ping() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                if (!chrome.runtime) {
                    reject();
                }
                else {
                    chrome.runtime.sendMessage(this.state.extensionId, { name: events_1.AqopiEventType.isInstalled }, response => {
                        // Do this to prevent a log in the console of: Unchecked runtime.lastError: Could not establish connection. Receiving end does not exist.
                        const lastError = chrome.runtime.lastError;
                        if (response && response.type === 'is-installed-notification')
                            resolve();
                        else
                            reject();
                        return true;
                    });
                }
            });
        });
    }
    install(options) {
        return __awaiter(this, void 0, void 0, function* () {
            // Open install url
            window.open(this.state.installationUrl);
            // Keep track of install attempts
            let attempts = 0;
            return new Promise((resolve, reject) => {
                const attemptInstall = () => {
                    if (chrome.runtime) {
                        chrome.runtime.sendMessage(this.state.extensionId, { name: events_1.AqopiEventType.isInstalled, setFocus: true }, response => {
                            // Do this to prevent a log in the console of: Unchecked runtime.lastError: Could not establish connection. Receiving end does not exist.
                            const lastError = chrome.runtime.lastError;
                            if (response && response.type === 'is-installed-notification') {
                                chrome.runtime.sendMessage(this.state.extensionId, { name: events_1.AqopiEventType.FocusRequestorRequest });
                                resolve();
                            }
                            else {
                                attempts++;
                                if (attempts < MAX_INSTALL_ATTEMPTS)
                                    setTimeout(attemptInstall, INSTALL_INTERVAL);
                                else
                                    reject();
                            }
                        });
                    }
                    else {
                        attempts++;
                        if (attempts < MAX_INSTALL_ATTEMPTS)
                            setTimeout(attemptInstall, INSTALL_INTERVAL);
                        else
                            reject();
                    }
                };
                attemptInstall();
            });
        });
    }
    uninstall() {
        chrome.runtime.sendMessage(this.state.extensionId, { name: events_1.AqopiEventType.UninstallRequest });
    }
    startQuery(id, data, usernameHash) {
        return __awaiter(this, void 0, void 0, function* () {
            // Init query executor
            this.queryExecutor = new query_executor_1.QueryExecutor({ configEndpoint: extension_api_config_1.ExtensionApiConfig.chromeParams.configEndpoint, usernameHash: usernameHash });
            // Register events
            this.queryExecutor.on('progress', (event) => this.triggerEvent('progress', event));
            this.queryExecutor.on('authenticate', (ready) => this.triggerEvent('authenticate', ready));
            this.queryExecutor.on('authenticated', (done) => this.triggerEvent('authenticated', done));
            // Execute the query
            return yield this.queryExecutor.run(id, data);
        });
    }
    cancelCurrentQuery() {
        if (this.queryExecutor)
            this.queryExecutor.cancel();
    }
    setStickyCredentials(sticky) {
        chrome.runtime.sendMessage(this.state.extensionId, { name: events_1.AqopiEventType.SetStickyCredsRequest, sticky });
    }
    focusRequestor() {
        chrome.runtime.sendMessage(this.state.extensionId, { name: events_1.AqopiEventType.FocusRequestorRequest });
    }
    closeTab() {
        chrome.runtime.sendMessage(this.state.extensionId, { name: events_1.AqopiEventType.CloseTabRequest });
    }
    on(event, handler) { super.on(event, handler); }
    off(event, handler) { super.off(event, handler); }
}
exports.ChromeExternallyConnectableExtensionBridge = ChromeExternallyConnectableExtensionBridge;
