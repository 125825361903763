"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
// Constants
const LOGGING_SERVICE_ENDPOINT_MAP = {
    'dev': 'https://logging.service.iwize.nl/env/dev/api/v1',
    'test': 'https://logging.service.iwize.nl/env/test/api/v1',
    'acct': 'https://logging.service.iwize.nl/env/acct/api/v1',
    'prod': 'https://logging.service.iwize.nl/api/v1',
};
class Logger {
    constructor(ticket) {
        Logger.ticket = ticket;
    }
    /* --- Statics --- */
    static setEnv(env) {
        if (!(env in LOGGING_SERVICE_ENDPOINT_MAP)) {
            throw new Error(`Invalid ENV passed "${env}"`);
        }
        Logger.env = env;
    }
    static setOrigin(origin) {
        Logger.origin = origin;
    }
    static setTicket(ticket) {
        Logger.ticket = ticket;
    }
    static error(error, ticket) {
        return __awaiter(this, void 0, void 0, function* () {
            Logger.ensureEnv();
            return yield _log('error', { error }, ticket, Logger.env, Logger.origin);
        });
    }
    static warn(value, ticket) {
        return __awaiter(this, void 0, void 0, function* () {
            Logger.ensureEnv();
            return yield _log('warn', { value }, ticket, Logger.env, Logger.origin);
        });
    }
    static info(value, ticket) {
        return __awaiter(this, void 0, void 0, function* () {
            Logger.ensureEnv();
            return yield _log('info', { value }, ticket, Logger.env, Logger.origin);
        });
    }
    static log(type, data) {
        return __awaiter(this, void 0, void 0, function* () {
            Logger.ensureEnv();
            return yield _log(type, data, Logger.ticket, Logger.env, Logger.origin);
        });
    }
    static ensureEnv() {
        if (!Logger.env) {
            throw new Error('Logger ENV not set');
        }
    }
    /* --- Instanced --- */
    error(error) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Logger.error(error, Logger.ticket);
        });
    }
    warn(value) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Logger.warn(value, Logger.ticket);
        });
    }
    info(value) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Logger.info(value, Logger.ticket);
        });
    }
    log(type_1) {
        return __awaiter(this, arguments, void 0, function* (type, data = {}) {
            return yield Logger.log(type, data);
        });
    }
}
exports.Logger = Logger;
Logger.origin = 'iwize.nl/accelerate';
function _log(type, data, ticket, env, origin) {
    return __awaiter(this, void 0, void 0, function* () {
        const timestamp = new Date().toISOString();
        try {
            const response = yield fetch(`${LOGGING_SERVICE_ENDPOINT_MAP[env]}/log/event`, {
                method: 'POST',
                body: JSON.stringify(Object.assign(Object.assign({}, data), { origin,
                    type,
                    timestamp })),
                headers: {
                    'Content-Type': 'application/json',
                    ticket,
                },
            });
            if (response.status !== 200) {
                const errMsg = `Failed to log event. Logging service returned ${response.status} ${response.statusText}`;
                if (type !== 'log-error')
                    Logger.log('log-error', errMsg);
                console.error(errMsg);
            }
        }
        catch (error) {
            if (type !== 'log-error')
                Logger.log('log-error', { value: 'Error: ' + error.message });
            console.error('Log error: ' + error.message);
        }
    });
}
