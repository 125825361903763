"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstTenCharacters = void 0;
function getFirstTenCharacters(inputString) {
    if (inputString === null || inputString === undefined) {
        return '';
    }
    try {
        return inputString.slice(0, 10);
    }
    catch (e) {
        return 'Error slicing input' + e.message;
    }
}
exports.getFirstTenCharacters = getFirstTenCharacters;
