"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabClosedQueryFailure = exports.NoDataQueriedFailure = exports.NoValidDataIdsFailure = exports.UserInconsistencyFailure = exports.NotAvailableQueryFailure = exports.CanceledQueryFailure = exports.BusyQueryFailure = exports.ErrorQueryFailure = exports.QueryFailureType = void 0;
var QueryFailureType;
(function (QueryFailureType) {
    QueryFailureType["Error"] = "ERROR";
    QueryFailureType["Busy"] = "BUSY";
    QueryFailureType["Canceled"] = "CANCELED";
    QueryFailureType["NotAvailable"] = "NOT-AVAILABLE";
    QueryFailureType["UserInconsistency"] = "USER-INCONSISTENCY";
    QueryFailureType["NoValidDataIds"] = "NO_VALID_DATA_IDS";
    QueryFailureType["NoDataQueried"] = "NO_DATA_QUERIED";
    QueryFailureType["TabClosed"] = "TAB_CLOSED";
})(QueryFailureType || (exports.QueryFailureType = QueryFailureType = {}));
class QueryFailure extends Error {
    constructor(type, message) {
        super(message);
        // Assign type
        this.type = type;
    }
}
class ErrorQueryFailure extends QueryFailure {
    constructor(message) {
        super(QueryFailureType.Error, message);
    }
}
exports.ErrorQueryFailure = ErrorQueryFailure;
class BusyQueryFailure extends QueryFailure {
    constructor() {
        super(QueryFailureType.Busy);
    }
}
exports.BusyQueryFailure = BusyQueryFailure;
class CanceledQueryFailure extends QueryFailure {
    constructor(reasonCode) {
        super(QueryFailureType.Canceled);
        this.reasonCode = reasonCode;
    }
}
exports.CanceledQueryFailure = CanceledQueryFailure;
class NotAvailableQueryFailure extends QueryFailure {
    constructor() {
        super(QueryFailureType.NotAvailable);
    }
}
exports.NotAvailableQueryFailure = NotAvailableQueryFailure;
class UserInconsistencyFailure extends QueryFailure {
    constructor() {
        super(QueryFailureType.UserInconsistency);
    }
}
exports.UserInconsistencyFailure = UserInconsistencyFailure;
class NoValidDataIdsFailure extends QueryFailure {
    constructor(message) {
        super(QueryFailureType.NoValidDataIds, message);
    }
}
exports.NoValidDataIdsFailure = NoValidDataIdsFailure;
class NoDataQueriedFailure extends QueryFailure {
    constructor(message) {
        super(QueryFailureType.NoDataQueried, message);
    }
}
exports.NoDataQueriedFailure = NoDataQueriedFailure;
class TabClosedQueryFailure extends QueryFailure {
    constructor(message) {
        super(QueryFailureType.TabClosed, message);
    }
}
exports.TabClosedQueryFailure = TabClosedQueryFailure;
