"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElectronExtensionBridge = void 0;
// Import event source
const event_source_1 = require("../event-source/event-source");
// Import query executor
const query_executor_1 = require("../query-executor/query-executor");
// Import config
const extension_api_config_1 = require("../config/extension-api-config");
// No operation.
const noop = () => { };
class ElectronExtensionBridge extends event_source_1.EventSource {
    constructor(options) {
        super();
        // Init state
        this.state = {
            installationUrl: options.installUrl,
            configEndpoint: options.configEndpoint,
            extensionId: options.extensionId,
            debug: !!options.debug,
            installed: false,
        };
    }
    setLoggingStatus(loggingStatus) {
        noop();
    }
    ping() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            return (_a = window.electronBridge) === null || _a === void 0 ? void 0 : _a.ping();
        });
    }
    install(options) {
        return __awaiter(this, void 0, void 0, function* () {
            noop();
        });
    }
    uninstall() {
        noop();
    }
    startQuery(id, data, usernameHash) {
        return __awaiter(this, void 0, void 0, function* () {
            // Init query executor
            this.queryExecutor = new query_executor_1.QueryExecutor({ configEndpoint: extension_api_config_1.ExtensionApiConfig.electronParams.configEndpoint, usernameHash: usernameHash });
            // Register events
            this.queryExecutor.on('progress', (event) => this.triggerEvent('progress', event));
            this.queryExecutor.on('authenticate', (ready) => this.triggerEvent('authenticate', ready));
            this.queryExecutor.on('authenticated', (done) => this.triggerEvent('authenticated', done));
            // Execute the query
            return yield this.queryExecutor.run(id, data);
        });
    }
    cancelCurrentQuery() {
        if (this.queryExecutor)
            this.queryExecutor.cancel();
    }
    setStickyCredentials(sticky) {
        noop();
    }
    focusRequestor() {
        noop();
    }
    closeTab() {
        noop();
    }
    on(event, handler) { super.on(event, handler); }
    off(event, handler) { super.off(event, handler); }
}
exports.ElectronExtensionBridge = ElectronExtensionBridge;
