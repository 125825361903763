(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("brondata", [], factory);
	else if(typeof exports === 'object')
		exports["brondata"] = factory();
	else
		root["brondata"] = root["brondata"] || {}, root["brondata"]["extension.api"] = factory();
})(self, () => {
return 