"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSessionId = exports.getSessionId = void 0;
let sessionId = null;
const setSessionId = (newSessionId) => {
    sessionId = newSessionId;
};
exports.setSessionId = setSessionId;
const getSessionId = () => {
    return sessionId;
};
exports.getSessionId = getSessionId;
