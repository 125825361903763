"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionExecutor = void 0;
// Dependecy injection imports
const tsyringe_1 = require("tsyringe");
// Import global config
const extension_api_config_1 = require("../config/extension-api-config");
// Import query session
const query_session_1 = require("./query-session");
// Import logging utils
const logging_1 = require("../utils/logging");
const logging_2 = require("../utils/logging");
const session_id_1 = require("../utils/session-id");
// Properties
const parsers = {
    parserFactory: null,
    processParser: null
};
let extensionBridge;
let installed = false;
let querySession;
let _options = null;
let _debug = false;
// Handlers
let onStartQueryHandler = () => { };
let onQueryProgressHandler = () => { };
let onAuthenticateHandler = () => { };
let onAuthenticatedHandler = () => { };
let onQuerySuccessHandler = () => { };
let onQueryFailureHandler = () => { };
let onDoneHandler = () => { };
// event api
const api = {
    onStartQuery: (onStartQueryFunc) => {
        // Assign new handler
        querySession.off('query-start', onStartQueryFunc);
        onStartQueryHandler = onStartQueryFunc;
        querySession.on('query-start', onStartQueryHandler);
        return api;
    },
    onQueryProgress: (onQueryProgressFunc) => {
        // Assign new handler
        querySession.off('query-progress', onQueryProgressHandler);
        onQueryProgressHandler = onQueryProgressFunc;
        querySession.on('query-progress', onQueryProgressHandler);
        return api;
    },
    onAuthenticate: (onAuthenticateFunc) => {
        // Assign new handler
        querySession.off('authenticate', onAuthenticateHandler);
        onAuthenticateHandler = onAuthenticateFunc;
        querySession.on('authenticate', onAuthenticateHandler);
        return api;
    },
    onAuthenticated: (onAuthenticatedFunc) => {
        // Assign new handler
        querySession.off('authenticated', onAuthenticatedHandler);
        onAuthenticatedHandler = onAuthenticatedFunc;
        querySession.on('authenticated', onAuthenticatedHandler);
        return api;
    },
    onQuerySuccess: (onQuerySuccessFunc) => {
        // Assign new handler
        querySession.off('query-success', onQuerySuccessHandler);
        onQuerySuccessHandler = onQuerySuccessFunc;
        querySession.on('query-success', onQuerySuccessHandler);
        return api;
    },
    onQueryFailure: (onQueryFailureFunc) => {
        // Assign new handler
        querySession.off('query-fail', onQueryFailureHandler);
        onQueryFailureHandler = onQueryFailureFunc;
        querySession.on('query-fail', onQueryFailureHandler);
        return api;
    },
    onDone: (onDoneFunc) => {
        // Assign new handler
        querySession.off('done', onDoneHandler);
        onDoneHandler = onDoneFunc;
        querySession.on('done', onDoneHandler);
        return api;
    }
};
function init(config_1, parserFactory_1, processParser_1) {
    return __awaiter(this, arguments, void 0, function* (config, parserFactory, processParser, uninstallSuppress = false, debug = false, options = null) {
        // Assign global config
        extension_api_config_1.ExtensionApiConfig.chromeParams = config.chromeParams;
        extension_api_config_1.ExtensionApiConfig.edgeParams = config.edgeParams;
        extension_api_config_1.ExtensionApiConfig.firefoxParams = config.firefoxParams;
        extension_api_config_1.ExtensionApiConfig.electronParams = config.electronParams;
        // Set parsers
        parsers.parserFactory = parserFactory;
        parsers.processParser = processParser;
        // Set options
        _options = options;
        // Set debug
        _debug = debug;
        // Set session ID
        if (options && (typeof options.sessionId === 'string')) {
            if (options.sessionId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
                (0, session_id_1.setSessionId)(options.sessionId);
            }
        }
        // If uninstall is suppressed, show warning
        if (uninstallSuppress) {
            // Console message
            console.warn(`[Brondata extension api] Extension uninstall is being suppressed. do NOT use this in production.`);
            // DOM message
            // generateUninstallSupressionWarning();
        }
        // Init extension bridge
        extensionBridge = tsyringe_1.container.resolve('ExtensionBridge');
        try {
            // Attempt to ping
            yield extensionBridge.ping();
            // Mark installed
            installed = true;
            // Extension already installed, uninstall it
            if (!uninstallSuppress)
                uninstall();
        }
        catch (err) {
            // Extension not yet installed
            installed = false;
        }
        // Return installed status
        return installed;
    });
}
function install(options) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!extensionBridge)
            throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
        if (installed)
            throw new Error('Extension already installed.');
        // Install extension
        yield extensionBridge.install(options);
        // Extension has been installed
        installed = true;
    });
}
function uninstall() {
    if (!extensionBridge)
        throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
    if (!installed)
        throw new Error('Extension not installed.');
    // Uninstall extension
    extensionBridge.uninstall();
    // Extension has been uninstalled
    installed = false;
}
function startQuerySession(configRequestList, ticket, env) {
    if (!extensionBridge)
        throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
    if (!installed)
        throw new Error('Extension not installed.');
    // Add jwt to options when available
    if (ticket) {
        _options = _options || {};
        _options.ticket = ticket;
        _options.env = env || null;
    }
    // Init logging v2
    (0, logging_2.initLogging)(_options);
    // Init new query session
    querySession = new query_session_1.QuerySession(configRequestList, extensionBridge, parsers.parserFactory, parsers.processParser, _debug, _options);
    // Assign handlers
    querySession.on('query-start', onStartQueryHandler);
    querySession.on('query-progress', onQueryProgressHandler);
    querySession.on('authenticate', onAuthenticateHandler);
    querySession.on('authenticated', onAuthenticatedHandler);
    querySession.on('query-success', onQuerySuccessHandler);
    querySession.on('query-fail', onQueryFailureHandler);
    querySession.on('done', onDoneHandler);
    // Log start query session
    (0, logging_1.logv2)('start-query-session', { value: document.location.host });
    // Return api
    return api;
}
function hasNext() {
    if (!extensionBridge)
        throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
    if (!installed)
        throw new Error('Extension not installed.');
    if (!querySession)
        throw new Error('No query session active. Ensure SessionExecutor.startQuerySession has been called.');
    // Return has next
    return querySession.hasNext();
}
function next() {
    if (!extensionBridge)
        throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
    if (!installed)
        throw new Error('Extension not installed.');
    if (!querySession)
        throw new Error('No query session active. Ensure SessionExecutor.startQuerySession has been called.');
    // Run next query
    querySession.runNextQuery();
}
function skip() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!extensionBridge)
            throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
        if (!installed)
            throw new Error('Extension not installed.');
        if (!querySession)
            throw new Error('No query session active. Ensure SessionExecutor.startQuerySession has been called.');
        // Skip query
        return (yield querySession.skipQuery()).hasNext;
    });
}
function cancelCurrentQuery() {
    if (!extensionBridge)
        throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
    if (!installed)
        throw new Error('Extension not installed.');
    if (!querySession)
        throw new Error('No query session active. Ensure SessionExecutor.startQuerySession has been called.');
    // Cancel current query
    querySession.cancelCurrentQuery();
}
function getCurrentConfigId() {
    if (!extensionBridge)
        throw new Error('Extension bridge not initialzed. Ensure SessionExecutor.init has been called.');
    if (!installed)
        throw new Error('Extension not installed.');
    if (!querySession)
        throw new Error('No query session active. Ensure SessionExecutor.startQuerySession has been called.');
    // Return current config id
    return querySession.getCurrentConfigId();
}
function generateUninstallSupressionWarning() {
    // DOM message
    const div = document.createElement('div');
    // Style
    div.style.position = 'absolute';
    div.style.bottom = '0';
    div.style.right = '0';
    div.style.width = '30ch';
    div.style.zIndex = '100';
    div.style.backgroundColor = '#f7ff5c';
    div.style.padding = '0em 2ch';
    div.innerHTML = `
        <h3>Development Mode</h3>
        <p>Extension uninstall is being suppressed. This should NOT be used in production due to security concerns.</p>
        <p style="opacity:.4">Brondata extension api</p>
    `;
    document.body.appendChild(div);
    setTimeout(() => {
        document.body.removeChild(div);
    }, 3000);
}
exports.SessionExecutor = {
    init, install, uninstall, startQuerySession, hasNext, next, skip, cancelCurrentQuery, getCurrentConfigId
};
