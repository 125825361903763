"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doWait = void 0;
const doWait = (callback, duration) => {
    const startTime = Date.now();
    const interval = setInterval(() => {
        if ((Date.now() - startTime) >= duration) {
            clearInterval(interval);
            callback();
        }
    }, 300);
};
exports.doWait = doWait;
