"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternallyConnectableChannelProxy = void 0;
// Import event source
const event_source_1 = require("../../event-source/event-source");
const events_1 = require("../../types/events");
class ExternallyConnectableChannelProxy extends event_source_1.EventSource {
    constructor(extensionId) {
        super();
        this.extensionId = extensionId;
        /* --- Helpers --- */
        this.handleMessage = (msg) => {
            if (!msg || msg.name !== 'aqopi-to-requestor')
                return;
            // Trigger event
            this.triggerEvent('message', msg);
            // // Handle the message
            // if (msg.type === 'aqopi-query-in-progress-notification')
            //     this.queryExecutor.handleQueryInProgress();
            // else if (msg.type === 'aqopi-tab-closed-notification')
            //     this.queryExecutor.handleTabClosed();
            // else if (msg.type === 'aqopi-event-notification')
            //     this.queryExecutor.handleEvent(msg.request);
            // else
            //     console.error(`Channel proxy received unknown message type: ${(msg as any).type}`);
        };
        // Connect to chrome extension
        this.port = chrome.runtime.connect(this.extensionId, { name: 'aqopi-query' });
        // Add listener
        this.port.onMessage.addListener(this.handleMessage);
    }
    openTab(cookies, selectorMap) {
        return new Promise(resolve => {
            chrome.runtime.sendMessage(this.extensionId, { name: events_1.AqopiEventType.OpenTabRequest, cookies, selectorMap }, () => resolve());
        });
    }
    setTemplates(templates) {
        chrome.runtime.sendMessage(this.extensionId, { name: events_1.AqopiEventType.SetTemplatesRequest, templates });
    }
    closeTab() {
        chrome.runtime.sendMessage(this.extensionId, { name: events_1.AqopiEventType.CloseTabRequest });
    }
    handleSetFocusToRequestorTab() {
        chrome.runtime.sendMessage(this.extensionId, { name: events_1.AqopiEventType.FocusRequestorRequest });
    }
    handleSetFocusToContentTab() {
        chrome.runtime.sendMessage(this.extensionId, { name: events_1.AqopiEventType.FocusContentRequest });
    }
    handleAction(action) {
        chrome.runtime.sendMessage(this.extensionId, { name: events_1.AqopiEventType.HandleActionRequest, action });
    }
    release() {
        this.port.disconnect();
    }
    on(event, handler) { super.on(event, handler); }
    off(event, handler) { super.off(event, handler); }
}
exports.ExternallyConnectableChannelProxy = ExternallyConnectableChannelProxy;
