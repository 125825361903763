"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logv2 = exports.initLogging = void 0;
const Logger_1 = require("./Logger");
const getEnvFromUrl_1 = require("./getEnvFromUrl");
let loggingActive = false;
const initLogging = (options) => {
    if (options === null || options === void 0 ? void 0 : options.ticket) {
        Logger_1.Logger.setTicket(options.ticket);
        // Set logging environment
        if (options.env === 'dev' || options.env === 'test' || options.env === 'acct' || options.env === 'prod') {
            Logger_1.Logger.setEnv(options.env);
        }
        else {
            Logger_1.Logger.setEnv((0, getEnvFromUrl_1.getEnvFromUrl)(document.location.href, document.location.host));
        }
        // Set logging origin
        if (options.customerId) {
            Logger_1.Logger.setOrigin(options.customerId);
        }
        else {
            Logger_1.Logger.setOrigin('iwize.nl' + document.location.pathname);
        }
        loggingActive = true;
    }
    else {
        loggingActive = false;
    }
};
exports.initLogging = initLogging;
const logv2 = (type, data) => {
    if (loggingActive) {
        Logger_1.Logger.log(type, data);
    }
};
exports.logv2 = logv2;
