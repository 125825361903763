"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = exports.insert = void 0;
function insert(frameUri) {
    const iwizeHtmlFragment = `
        <div id="iwize">
            <iframe
                id="iwize-frame"
                src="${frameUri}"
                width="0"
                height="0"
                frameborder="0">
            </iframe>		
        </div>
    `;
    const frag = document.createRange().createContextualFragment(iwizeHtmlFragment);
    document.body.appendChild(frag);
}
exports.insert = insert;
function remove() {
    if (document.querySelector('#iwize')) {
        document.querySelector('#iwize').outerHTML = '';
    }
}
exports.remove = remove;
exports.default = {
    insert, remove
};
