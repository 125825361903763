"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.browserName = exports.BrowserInfo = void 0;
// Setup browserinfo
exports.BrowserInfo = {
    chrome: navigator.userAgent.includes('Chrome'),
    explorer: navigator.userAgent.includes('MSIE'),
    firefox: navigator.userAgent.includes('Firefox'),
    safari: navigator.userAgent.includes('Safari'),
    camino: navigator.userAgent.includes('Camino'),
    opera: navigator.userAgent.includes('op'),
    edge: /Edg\/\d./i.test(navigator.userAgent)
};
if ((exports.BrowserInfo.chrome) && (exports.BrowserInfo.safari))
    exports.BrowserInfo.safari = false;
if ((exports.BrowserInfo.chrome) && (exports.BrowserInfo.opera))
    exports.BrowserInfo.chrome = false;
if ((exports.BrowserInfo.chrome) && (exports.BrowserInfo.edge))
    exports.BrowserInfo.chrome = false;
let browserName = '';
exports.browserName = browserName;
if (exports.BrowserInfo.chrome) {
    exports.browserName = browserName = 'Chrome';
}
else if (exports.BrowserInfo.firefox) {
    exports.browserName = browserName = 'Firefox';
}
else if (exports.BrowserInfo.edge) {
    exports.browserName = browserName = 'Edge';
}
exports.default = exports.BrowserInfo;
